import React from 'react'
import Mybreadcrumb from '@components/Mybreadcrumb'

const Dashboard = () => {

    return (
        <div>
            <Mybreadcrumb path={[{ title: 'Dashborad' }]} />
            啥也没有的控制台
        </div>
    )
}

export default Dashboard
